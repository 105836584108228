<template>
  <footer>
    <div class="container p-5 has-text-light">
      <div class="flex-grid">
        <div class="col">
          <h3>About Us</h3>
          <p>About</p>
          <p>Contact</p>
          <p>Submit an issue</p>
        </div>
        <div class="col">
          <h3>More Links</h3>
          <p>Directions</p>
          <p>Support</p>
          <p>Meet our team</p>
        </div>
        <div class="col">
          <h3>Connect</h3>
          <p>Twitter</p>
          <p>Facebook</p>
          <p>Instagram</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped>
.flex-grid {
  display: flex;
}
.col {
  flex: 1;
  margin-bottom: 1.5em;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .flex-grid {
    display: block;
  }
}
</style>
