<template>
  <teleport to="body"
    ><div class="parent" @click="$emit('close-modal')"></div>
    <dialog open>
      <header class="has-background-dark p-3">
        <h1>{{ title }}</h1>
      </header>
      <div class="content">
        <section class="mb-3">
          <slot></slot>
        </section>
        <div class="buttons">
          <slot name="buttons">
            <base-button @click="$emit('close-modal')"></base-button>
          </slot>
        </div></div></dialog
  ></teleport>
</template>

<script>
export default {
  emits: ['close-modal'],
  props: {
    title: {
      type: String,
      required: false
    }
  }
};
</script>

<style scoped>
.parent {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.55);
  z-index: 10;
}
dialog {
  position: fixed;
  z-index: 99;
  width: 75%;
  top: 20%;
  padding: 0;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 0px 12px 0px #222222;
}

dialog h1 {
  color: #f2f2f2;
}
header {
  border-radius: 8px 8px 0 0;
}
.content {
  padding: 1em;
}
</style>
