<template>
  <nav class="navbar p-5">
    <div class="container">
      <h1>{{ title }}</h1>
    </div>
  </nav>
</template>

<script>
export default {
  props: ['title']
};
</script>

<style scoped>
.navbar {
  background: #121414;
}
.svg-logo {
  height: 50px;
}
.letters {
  fill: #f2f2f2;
}
.container {
  justify-content: space-between;
}
</style>
