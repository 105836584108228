<template>
  <base-modal :title="title">
    <template #default>
      <form @submit.prevent="addContactInfo">
        <div class="field">
          <label>First Name:</label>
          <input type="text" class="input" v-model="inputFirst" />
        </div>
        <div class="field">
          <label>Last Name:</label>
          <input type="text" class="input" v-model="inputLast" />
        </div>
        <div class="field">
          <label>Phone Number:</label>
          <input type="tel" class="input" v-model="inputPhone" />
        </div>
        <div class="field">
          <label>Avatar:</label>
          <input type="url" class="input" v-model="inputAvatar" />
        </div>
      </form>
    </template>
    <template #buttons
      ><base-button
        size="medium"
        kind="is-primary"
        type="button"
        @click="editContactInfo"
        >Save Changes</base-button
      >
      <base-button
        size="medium"
        kind="is-danger"
        type="button"
        @click="closeModal"
        >Cancel</base-button
      >
    </template>
  </base-modal>
</template>

<script>
export default {
  props: ['contact', 'title'],
  emits: ['close-edit'],
  inject: ['editContact'],
  data() {
    return {
      inputFirst: this.contact.firstName,
      inputLast: this.contact.lastName,
      inputPhone: this.contact.phone,
      inputAvatar: this.contact.avatar,
      id: this.contact.id
    };
  },
  methods: {
    editContactInfo() {
      this.editContact({
        firstName: this.inputFirst,
        lastName: this.inputLast,
        phone: this.inputPhone,
        avatar: this.inputAvatar,
        id: this.contact.id
      });
      // this.closeModal();
    },
    closeModal() {
      this.$emit('close-edit');
    }
  },
  mounted() {}
};
</script>
