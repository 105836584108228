<template>
  <base-modal :title="title">
    <template #default>
      <form @submit.prevent="addContactInfo">
        <div class="field">
          <label class="label">First Name:</label>
          <input type="text" class="input" v-model="inputFirst" />
        </div>
        <div class="field">
          <label class="label">Last Name:</label>
          <input type="text" class="input" v-model="inputLast" />
        </div>
        <div class="field">
          <label class="label">Phone Number:</label>
          <input type="tel" class="input" v-model="inputPhone" />
        </div>
        <div class="field">
          <label class="label">Avatar:</label>
          <input type="url" class="input" v-model="inputAvatar" />
        </div>
      </form>
    </template>
    <template #buttons
      ><base-button
        size="medium"
        kind="is-link"
        type="button"
        @click="addContactInfo"
        >Add Contact</base-button
      >
      <base-button
        size="medium"
        kind="is-link is-light"
        type="button"
        @click="closeModal"
        >Cancel</base-button
      >
    </template>
  </base-modal>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    }
  },
  emits: ['show-modal'],
  inject: ['addContact'],
  data() {
    return {
      inputFirst: '',
      inputLast: '',
      inputPhone: '',
      inputAvatar: ''
    };
  },
  methods: {
    addContactInfo() {
      this.addContact({
        firstName: this.inputFirst,
        lastName: this.inputLast,
        phone: this.inputPhone,
        avatar: this.inputAvatar
      });
      this.closeModal();
    },
    closeModal() {
      this.$emit('show-modal');
    }
  }
};
</script>
