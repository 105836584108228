<template>
  <button :class="['button', kind, size]">
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: ['kind', 'size']
};
</script>

<style scoped>
.button.is-link.is-light {
  background-color: #77d9e2;
  color: #f5f5f5;
}
.button.is-link.is-light:hover {
  background: #92eaf1;
}
</style>
