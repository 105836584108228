<template>
  <div
    :class="[
      'contact',
      'has-text-light',
      { odd: idx % 2 === 0, even: idx % 2 !== 0 }
    ]"
  >
    <img :src="avatar" v-if="avatar" />
    <div class="placeholder-avatar" v-else>
      <i class="fa fa-picture-o" aria-hidden="true"></i>
    </div>

    <div class="contact-info">
      <div class="inner">
        <p><strong>Name: </strong>{{ firstName }} {{ lastName }}</p>
        <p><strong>Phone: </strong>{{ phone }}</p>
      </div>
    </div>
    <div class="user-edits mr-5 is-block-touch">
      <span class="icon mr-4 is-size-4">
        <i @click="editContact" class="fas fa-user-edit"></i>
      </span>
      <span class="icon is-size-4">
        <i @click="deleteContact" class="fas fa-minus-circle"></i>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  emits: ['delete-contact', 'edit-contact'],
  props: ['firstName', 'lastName', 'phone', 'avatar', 'id', 'idx'],
  methods: {
    deleteContact() {
      this.$emit('delete-contact', this.id);
    },
    editContact() {
      this.$emit('edit-contact', this.id);
    }
  }
};
</script>

<style scoped lang="scss">
.user-edits {
  align-self: center;
  height: auto;
  cursor: pointer;
  display: none;
}
.fas {
  transition: color 200ms linear;
  color: #888888;
}
.icon .fa-user-edit:hover {
  color: #effaf3;
}
.icon .fa-minus-circle:hover {
  color: #cc0f35;
}
img {
  width: 100px;
  margin-right: 1em;
  filter: grayscale(1);
  transition: filter 200ms linear;
}
strong {
  color: #f5f5f5;
}
.odd {
  background: #282f2f;
}
.contact {
  display: flex;
  transition: filter(grayscale);
}
.contact-info {
  flex-grow: 1;
  align-self: stretch;
  display: flex;
  align-items: center;
}

// 1f2424
.contact:hover .user-edits {
  display: block;
}

.contact:hover img {
  filter: grayscale(0);
}
.placeholder-avatar {
  text-align: center;
  font-size: 4.5em;
  width: 100px;
  height: 100px;
  background: #dfdfdf;
  margin-right: 0.25em;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
@media screen and (max-width: 768px) {
  .placeholder-avatar {
    width: 64px;
    height: 64px;
    font-size: 2em;
    margin-top: 0.5em;
    margin-right: 0.5em;
  }
  .fa {
    margin-top: 0.5em;
  }
  img {
    width: 64px;
  }
}
</style>
