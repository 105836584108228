<template>
  <AddContact
    v-if="showModal"
    title="Add Contact"
    @show-modal="showAddContact"
  />
  <EditContact
    v-if="contactEditIdx !== null"
    title="Edit Contact"
    :contact="contacts[contactEditIdx]"
    @close-edit="resetEditIdx"
  />
  <TheLoader v-if="isLoading" />
  <div>
    <div class="level">
      <div class="level-left">
        <form>
          <div class="select mr-3 ">
            <select
              @change="sorter(nameSort, direction)"
              id="name-sort"
              name="name-sort"
              v-model="nameSort"
            >
              <option value="firstName">First Name</option>
              <option value="lastName">Last Name</option>
            </select>
          </div>
          <div class="select mr-3 ">
            <select
              @change="sorter(nameSort, direction)"
              id="sort-direction"
              name="sort-direction"
              v-model="direction"
            >
              <option value="ascending">Ascending</option>
              <option value="descending">Descending</option>
            </select>
          </div>
        </form>
      </div>
      <div class="level-right">
        <base-button kind="is-light is-link" @click="showAddContact"
          >Add Contact</base-button
        >
      </div>
    </div>
    <div class="contacts">
      <contact
        v-for="(contact, idx) in contacts"
        :key="contact.id"
        :id="contact.id"
        :firstName="contact.firstName"
        :lastName="contact.lastName"
        :avatar="contact.avatar"
        :phone="contact.phone"
        :idx="idx"
        @delete-contact="deleteContact"
        @edit-contact="sendContact"
      ></contact>
    </div>
  </div>
</template>

<script>
import Contact from './Contact.vue';
import AddContact from './AddContact.vue';
import EditContact from './EditContact.vue';
import TheLoader from '../UI/TheLoader.vue';
export default {
  components: {
    Contact,
    AddContact,
    EditContact,
    TheLoader
  },
  data() {
    return {
      showModal: false,
      isLoading: true,
      showEditModal: false,
      contactToEdit: null,
      contactEditIdx: null,
      nameSort: 'firstName',
      direction: 'ascending',
      contacts: JSON.parse(localStorage.getItem('contacts')) || [
        {
          id: 'kevin2342342',
          firstName: 'Aryeh',
          lastName: 'Kobrinsky',
          phone: '347-622-3013',
          avatar:
            'https://media-exp1.licdn.com/dms/image/C5603AQEAtDkL8MRfdg/profile-displayphoto-shrink_400_400/0/1593893441640?e=1617235200&v=beta&t=V7jqg1hgFRL49SfvhVgRzlwvTl5er7SGBhqjEk3PfXw'
        },
        {
          id: 'joe2832u4',
          firstName: 'Joe',
          lastName: 'Biden',
          phone: '551-534-0912',
          avatar:
            'https://www.history.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTc2MzAyNDY4NjM0NzgwODQ1/joe-biden-gettyimages-1267438366.jpg'
        },
        {
          id: 'elonsapidhfj',
          firstName: 'Elon',
          lastName: 'Musk',
          phone: '551-534-0912',
          avatar:
            'https://img.etimg.com/thumb/width-640,height-480,imgsize-601228,resizemode-1,msid-80417419/industry/telecom/telecom-news/elon-musk-targets-telecom-for-next-disruption-with-starlink-internet/elon-musk-is-the-worlds-second-richest-person-with-an-estimated-net-worth-of-158-billion-.jpg'
        }
      ]
    };
  },
  provide() {
    return {
      addContact: this.addContact,
      deleteContact: this.deleteContact,
      sendContact: this.sendContact,
      editContact: this.editContact
    };
  },

  methods: {
    addContact(contact) {
      const newFriend = { id: new Date().toISOString(), ...contact };
      this.contacts.push(newFriend);
      this.sorter(this.nameSort, this.direction);
      this.saveContacts();
    },
    deleteContact(id) {
      const contactIdx = this.contacts.findIndex(contact => contact.id === id);
      this.contacts.splice(contactIdx, 1);
      this.saveContacts();
    },
    showAddContact() {
      this.showModal = !this.showModal;
    },
    resetEditIdx() {
      this.contactEditIdx = null;
    },
    sendContact(id) {
      const contactIdx = this.contacts.findIndex(contact => contact.id === id);
      this.contactEditIdx = contactIdx;
    },
    editContact(contact) {
      const editedContact = { ...contact };
      this.contacts[this.contactEditIdx] = editedContact;
      this.sorter(this.nameSort, this.direction);
      this.saveContacts();
    },
    saveContacts() {
      const parsed = JSON.stringify(this.contacts);
      localStorage.setItem('contacts', parsed);
    },
    sorter(type, direction) {
      this.contacts.sort((a, b) => {
        if (a[type] < b[type]) return -1;
        if (a[type] > b[type]) return 1;
        return 0;
      });
      direction === 'descending' ? this.contacts.reverse() : null;
      if (this.contactEditIdx !== null) this.contactEditIdx = null;
    }
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
      if (this.contacts.length > 0) {
        this.sorter(this.nameSort, this.direction);
        this.saveContacts();
      }
    }, 3000);
  }
};
</script>
